import { useEffect } from 'react';

type TProps = {
  locale: string | undefined;
};

const CludoSuggestedComponent = ({ locale }: TProps) => {
  useEffect(() => {
    // Check if the script is already present
    const existingScript = document.getElementById('cludo-404-script');
    if (!existingScript) {
      const scriptElement = document.createElement('script');
      scriptElement.id = 'cludo-404-script';
      scriptElement.setAttribute('data-cid', '2677');
      scriptElement.setAttribute('data-eid', locale === 'da' ? '13262' : '13330');
      scriptElement.src = 'https://customer.cludo.com/scripts/404/cludo-404.js';

      const placeholder = document.getElementById('cludo-404');
      if (placeholder && placeholder.parentNode) {
        placeholder.parentNode.insertBefore(scriptElement, placeholder);
      }
    }
  }, [locale]);

  return <div id="cludo-404" />;
};

export default CludoSuggestedComponent;
