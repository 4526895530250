import { IApiContentResponseModel } from '@ingeniorforeningen/umbraco-client';
import { Container, Grid, Heading } from '@ingeniorforeningen/figurine-core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import tracker from '../../gtm';
import CludoSuggestedComponent from '../components/cludoSuggested/CludoSuggestedComponent';
import { getSettings } from '@/lib/apiHelpers';
import Layout from '@/layouts/Layout';

const Custom404 = ({ data: { settings } }: { data: { settings: IApiContentResponseModel } }) => {
  const { asPath, isPreview, locale } = useRouter();

  useEffect(() => {
    tracker.trackPageError(404, {
      url: asPath,
      isPreview,
    });
  }, [asPath, isPreview]);

  return (
    <Layout settings={settings}>
      <title>{locale === 'da' ? 'Siden kunne ikke findes' : 'Page not found'}</title>
      <Container style={{ paddingTop: '7em' }}>
        <Grid container>
          <Heading
            align="center"
            type="h1"
            text={locale === 'da' ? 'Vi kan ikke finde siden' : 'This page does not exist'}
          />
          <Heading
            align="center"
            type="h2"
            text={
              locale === 'da' ? 'Vi beklager - prøv i stedet:' : 'We are sorry - try these instead:'
            }
          />
          <CludoSuggestedComponent locale={locale} />
        </Grid>
      </Container>
    </Layout>
  );
};

export async function getStaticProps({ preview, locale }: { preview: boolean; locale: string }) {
  const settings = await getSettings({ preview, locale, path: '404' });

  return {
    props: { data: { settings }, preview: preview || null },
  };
}

export default Custom404;
